<template>
  <div>
    <SectionFilter @apply="setFilterData" />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && noRecord">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="(head, index) in tableHeaders" :key="index">
            <span v-i18n="dashboard">
              {{ head }}
            </span>
          </TH>
          <TH v-permission="{ basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL] }">Class</TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(section, index) in sectionsList" :key="index">
          <TD>
            {{ section.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ section.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-permission="{ basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL] }">
            {{ (section.class && section.class.title) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            <TableAction
              :current-user="section"
              :idx="index"
              :action-list="actionList"
              @action="handleAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(sectionsCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <EnrolTeacherModal
      v-if="enrolTeacherModal"
      :modal="enrolTeacherModal"
      :class-id="currentSection?.class_id"
      :section-id="currentSection?.id"
      @toggle="toggleEnrolment"
    />
    <AddAndEditSectionModal
      v-if="isSectionModalDisplay"
      :modal="isSectionModalDisplay"
      :edit="currentSection"
      @toggle="toggleClassModal"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_SECTION"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentSection.title"
      remaining-message="section ?"
      @cancel="toggleConfirmationModal"
      @confirm="deactivateSection"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import SectionFilter from '@src/router/views/sections/SectionFilters.vue'
import EnrolTeacherModal from '@src/router/views/admin/modals/EnrolTeacherModal.vue'
import AddAndEditSectionModal from '@/src/router/views/admin/modals/AddSectionModal.vue'
import TableAction from '@/src/components/TableAction.vue'
import { mapState, mapActions } from 'vuex'
import Pagination from '@components/BaseComponent/Pagination.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  paginationRangeHandler,
  paginationCounts,
} from '@src/components/BaseComponent/pagination.util.js'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SectionFilter,
    Loader,
    EnrolTeacherModal,
    AddAndEditSectionModal,
    TableAction,
    Pagination,
    NoRecordFound,
    UIConfirmationModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [scrollMixin],

  data() {
    return {
      GENERAL_CONSTANTS,
      SCOPE_LEVELS,
      dashboard: 'dashboard',
      tableHeaders: ['Section Title', 'Section description'],
      isLoading: false,
      noRecord: true,
      sectionsList: [],
      enrolTeacherModal: false,
      currentSection: null,
      actionList: [{ name: 'Assign Teacher' }, { name: 'Edit' }, { name: 'Delete' }],
      classId: '',
      sectionId: '',
      sectionsCounts: 0,
      filtersData: {},
      showPagination: false,
      confirmation: false,
      filteredRecordLimit: 10,
    }
  },
  page: {
    title: 'Section',
    meta: [
      {
        name: 'description',
        content: 'Sections Management',
      },
    ],
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      isSectionModalDisplay: (state) => state.layout.classAddModal,
      currentClassScope: (state) => state.layout.currentClassScope,
    }),
  },
  watch: {
    currentCampusScope: {
      immediate: true,
      deep: true,
      handler() {
        this.setRightBar()
        if (this.currentCampusScope?.id) this.filterRecord()
      },
    },
    currentClassScope: {
      handler() {
        this.setRightBar()
        this.filterRecord()
      },
    },
  },
  mounted() {
    this.setRightBar()
  },
  methods: {
    setFilterData(filtersPayload) {
      this.filtersData = filtersPayload
      this.filterRecord()
    },
    paginationCounts,
    /**
     * Filter Record
     * @param {Number} range
     * @returns {void}
     * @description filtering the record on the basis of range
     */
    filterRecord(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.filterSectionsList(payload)
        .then((res) => {
          this.noRecord = isEmpty(res.records)
          this.sectionsList = res.data.records
          this.sectionsCounts = res.data.meta.total_records
          this.showPagination = this.sectionsCounts > 10
          this.setRightBar(this.sectionsCounts)
        })
        .catch(() => {
          this.noRecord = true
        })
        .finally(() => {
          this.isLoading = false
          this.noRecord = isEmpty(this.sectionsList)
        })
    },
    setRightBar(records = 0) {
      this.setRightbarContent({
        header: {
          title: 'Sections',
          buttons: [
            {
              title: 'Add Section',
              classes: ['primary-button-right'],
              action: { name: 'layout/setClassAddModal' },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_NO_SECTIONS',
                value: `${records}`,
                tooltipDescription:
                  this.currentCampusScope && !this.currentClassScope
                    ? 'Total No. of all the Sections in Campus'
                    : 'Total No. of all the Sections in class',
              },
            ],
          },
        ],
      })
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentSection = null
    },
    /**
     * Toggle Enrolment
     * @param {boolean} payload
     * @returns {void}
     * @description toggling the enrolment of teacher
     */
    toggleEnrolment(payload) {
      if (payload) this.currentSection = payload
      this.enrolTeacherModal = !this.enrolTeacherModal
    },
    /**
     * Toggle Class Modal
     * @param {boolean} payload
     * @returns {void}
     * @description toggling the class modal
     */
    toggleClassModal(payload = false) {
      this.setClassAddModal(!this.isSectionModalDisplay)
      if (payload) this.filterRecord()
      if (!this.isSectionModalDisplay) this.currentSection = null
    },
    deactivateSection() {
      this.deleteSection(this.currentSection).then(() => {
        this.currentSection = null
        this.confirmation = false
        this.filterRecord()
      })
    },
    /**
     * Class Action
     * @param {String} action
     * @param {String} currentclass
     * @returns {void}
     * @description performing class actions on the basis of action and current-class
     */
    handleAction(action, currentSection) {
      this.currentSection = currentSection
      switch (action) {
        case 'Assign Teacher':
          this.toggleEnrolment()
          break
        case 'Edit':
          this.toggleClassModal()
          break
        case 'Delete':
          this.toggleConfirmationModal()
          break
      }
    },
    ...mapActions('section', ['filterSectionsList', 'deleteSection']),
    ...mapActions('layout', ['setRightbarContent', 'setClassAddModal']),
  },
}
</script>
