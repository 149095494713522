<template>
  <!-- <ValidationObserver v-slot="{ invalid }" tag="div"> -->
  <div>
    <TitleWrapper
      :filter-option="true"
      title="SECTS"
      tooltip-title="SECTS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper>
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="TITLE"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-if="!currentClassScope"
            v-model.trim="filtersPayload.class_id"
            title="CLASS"
            label="title"
            class="flex-1"
            :search-through-api="true"
            reduce="id"
            :options="classesList"
            :filter="FILTERS_LIST.CLASS"
            @emitQuery="updateQuery"
            @search="getCampusClasses"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import { getCountFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    InputFieldWrapper,
    TitleWrapper,
    UiSingleSelect,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTERS_LIST,
      $where: {},
      classesList: [],
      filtersCount: 0,
      filtersPayload: {
        title: '',
        class_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentClassScope: (state) => state.layout.currentClassScope,
      currentCampusScope: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    currentCampusScope: {
      handler(val) {
        if (val) this.getCampusClasses()
        this.clearFilters()
      },
    },
    currentClassScope: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getCampusClasses()
  },
  methods: {
    getCampusClasses(query = '') {
      let payload = {
        $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) },
        campus_id: this.currentCampusScope?.id,
      }
      this.getClassesList(payload).then((res) => {
        this.classesList = res.records
      })
    },
    getFiltersCount() {
      this.getCampusClasses()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('classes', ['getClassesList']),
  },
}
</script>
